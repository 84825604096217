// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-index-tsx": () => import("./../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-platform-tsx": () => import("./../../src/pages/platform.tsx" /* webpackChunkName: "component---src-pages-platform-tsx" */),
  "component---src-pages-registration-index-tsx": () => import("./../../src/pages/registration/index.tsx" /* webpackChunkName: "component---src-pages-registration-index-tsx" */),
  "component---src-pages-reset-password-index-tsx": () => import("./../../src/pages/reset_password/index.tsx" /* webpackChunkName: "component---src-pages-reset-password-index-tsx" */),
  "component---src-pages-restore-index-tsx": () => import("./../../src/pages/restore/index.tsx" /* webpackChunkName: "component---src-pages-restore-index-tsx" */)
}

