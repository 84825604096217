import {
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_FAILURE,
} from "@/constants/actionTypes";

import { NotificationActionsTypes } from "@/actions/notifications.actions";
import { NotificationListType } from "app/types";

export type InitialStateType = {
  notifications: NotificationListType[];
  error: string;
  page: number;
  pageSize: number;
  pages: number;
  count: number;
  notificationReadStatus: number;
};

const initialState: InitialStateType = {
  notifications: [],
  error: null,
  page: 1,
  pageSize: 10,
  pages: 0,
  count: 0,
  notificationReadStatus: 0,
};

const notifications = (
  state = initialState,
  action: NotificationActionsTypes
): InitialStateType => {
  switch (action.type) {
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.payload.notifications.records,
        error: null,
        page: action.payload.notifications.page,
        pages: action.payload.notifications.pages,
        pageSize: action.payload.notifications.pageSize,
        count: action.payload.notifications.count
      };
    case GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        notifications: [],
        page: 1,
        pages: 0,
        pageSize: 10,
        count: 0,
        error: action.payload.error,
      };
    case READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationReadStatus: action.payload.notificationReadStatus,
        error: null,
      };
    case READ_NOTIFICATION_FAILURE:
      return {
        ...state,
        notificationReadStatus: 0,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default notifications;
