import {
  IMPORT_CATEGORIES_FAILURE,
  IMPORT_CATEGORIES_SUCCESS,
  GET_TP_CATEGORIES_SUCCESS,
  GET_TP_CATEGORIES_FAILURE,
  GET_TP_CURRENT_CATEGORY,
  GET_CATEGORY_USER_ASSOCIATION_SUCCESS,
  GET_CATEGORY_USER_ASSOCIATION_FAILURE,
} from "@/constants/actionTypes";
import { CategoriesActionsTypes } from "@/actions/categories.actions";
import {
  CategoriesTpType,
  CategoriesType,
  CategoryUserAssociationType
} from "app/types";

type InitialStateType = {
  categories: CategoriesType[];
  categoriesPages: number;
  error: string;
  isFetching: boolean;
  categoriesImportStatus: number;
  categoriesImportError: string;
  tpCategory: CategoriesTpType;
  tpCategories: CategoriesTpType[];
  tpCategoryLvlOne: CategoriesTpType;
  tpCurrentCategory: CategoriesTpType;
  userAssociations: CategoryUserAssociationType[];
};

const initialState: InitialStateType = {
  categories: [],
  categoriesPages: 1,
  error: null,
  isFetching: true,
  categoriesImportStatus: 0,
  categoriesImportError: null,
  tpCategory: null,
  tpCategories: [],
  tpCategoryLvlOne: null,
  tpCurrentCategory: null,
  userAssociations: null,
};

const categories = (
  state = initialState,
  action: CategoriesActionsTypes
): InitialStateType => {
  switch (action.type) {
    case IMPORT_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoriesImportStatus: action.payload.categoriesImportStatus,
        categoriesImportError: null,
      };
    case IMPORT_CATEGORIES_FAILURE:
      return {
        ...state,
        categoriesImportStatus: 0,
        categoriesImportError: action.payload.categoriesImportError,
      };
    case GET_TP_CATEGORIES_SUCCESS:
      const tpCategories = action.payload.tpCategories;

      return {
        ...state,
        tpCategories,
        error: null,
      };
    case GET_TP_CATEGORIES_FAILURE:
      return {
        ...state,
        tpCategories: [],
        error: action.payload.error,
      };
    case GET_TP_CURRENT_CATEGORY:
      return {
        ...state,
        tpCurrentCategory: action.payload.tpCurrentCategory,
      }
    case GET_CATEGORY_USER_ASSOCIATION_SUCCESS:
      return {
        ...state,
        userAssociations: action.payload.associations,
        error: null,
      };
    case GET_CATEGORY_USER_ASSOCIATION_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default categories;
