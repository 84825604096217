import {
  SEARCH_GET,
  SEARCH_GET_SUCCESS,
  SEARCH_GET_FAILURE,
  SEARCH_PAGINATION_UPDATE,
  SEARCH_UPDATE_ENTITY_VALUE,
  SEARCH_GET_RESULT_REQUEST,
  SEARCH_GET_RESULT_SUCCESS,
  SEARCH_GET_RESULT_FAILURE,
  SEARCH_PAGINATION_REQUEST,
  SEARCH_PAGINATION_SUCCESS,
  SEARCH_PAGINATION_FAILURE,
} from "@/constants/actionTypes";

import { SearchActionsType } from "@/actions/search.actions";
import { SearchSectionType, SearchResultType } from "app/types";

const defaultResultFailure = {
  records: [],
  name: "",
  ordering: 0,
  path: "",
  page: 1,
  pages: 0,
  pageSize: 3,
  isFetching: false,
};

export type InitialStateType = {
  results: SearchResultType[];
  productsDetailed: {
    count: number;
    results: [];
  };
  next: string;
  isFetching: boolean;
  sections: SearchSectionType[];
  previous: string;
  searchValue: string;
  faq: { order: number; request: string }[];
  isFAQFetching: boolean;
};

const initialState: InitialStateType = {
  results: [],
  sections: [],
  productsDetailed: {
    count: null,
    results: [],
  },
  isFetching: false,
  next: "",
  previous: null,
  searchValue: null,
  faq: [],
  isFAQFetching: true,
};

const search = (
  state = initialState,
  action: SearchActionsType
): InitialStateType => {
  switch (action.type) {
    case SEARCH_GET_SUCCESS:
      return {
        ...state,
        productsDetailed: {
          count: action.payload.count,
          results: action.payload.results,
        },
      };
    case SEARCH_GET_FAILURE:
      return { ...state };
    case SEARCH_UPDATE_ENTITY_VALUE:
      const { entity, value } = action.payload;
      return {
        ...state,
        [entity]: value,
      };
    case SEARCH_GET_RESULT_REQUEST:
      return { ...state };
    case SEARCH_GET_RESULT_SUCCESS:
      const currentResults: SearchResultType[] = [...state.results];

      const { data } = action.payload;

      const newCurrentResults: SearchResultType[] = currentResults.filter(
        (r) => r.path !== data.path
      );

      newCurrentResults.push(data);

      return {
        ...state,
        results: newCurrentResults,
        isFetching: false,
      };
    case SEARCH_GET_RESULT_FAILURE:
      const { path, name, ordering } = action.payload;
      const prevGetResultsFailure = [...state.results];

      prevGetResultsFailure.push({
        ...defaultResultFailure,
        path,
        name,
        ordering,
      });

      return {
        ...state,
        results: prevGetResultsFailure,
        isFetching: false,
      };
    case SEARCH_PAGINATION_REQUEST:
      const { nameRequest } = action.payload;
      const prevResultsRequest = [...state.results];

      const newResultsRequest = prevResultsRequest.map((r) => {
        if (r.name === nameRequest) {
          r.isFetching = true;
        }

        return r;
      });
      return { ...state, results: newResultsRequest };
    case SEARCH_PAGINATION_SUCCESS:
      const { results } = action.payload;
      const prevResultsPagination = [...state.results];

      const newResultsPagination = prevResultsPagination.map((r) => {
        if (r.name === results.name) {
          const { isFetching, page } = results;
          r.isFetching = isFetching;
          r.page = page;
          r.records = [...r.records, ...results.records];
        }

        return r;
      });
      return { ...state, results: newResultsPagination };
    case SEARCH_PAGINATION_FAILURE:
      const { nameFailure } = action.payload;
      const prevResultsFailure = [...state.results];

      const newResultsFailure = prevResultsFailure.map((r) => {
        if (r.name === nameFailure) {
          r.isFetching = false;
        }

        return r;
      });

      return { ...state, results: newResultsFailure };
    default:
      return state;
  }
};

export default search;
